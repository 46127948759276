<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <section class="d-flex">
      <!-- Filtrar por Nivel -->
      <v-autocomplete class="me-2 filtro" v-model="buscar.nivel" label="Nivel" :items="niveles" outlined dense
        hide-details :menu-props="{ offsetY: true }">
      </v-autocomplete>
      <!-- Filtrar por año -->
      <v-text-field class="me-2 filtro" v-model="buscar.ano" label="Año" outlined dense hide-details></v-text-field>

      <!-- Botón para agregar la cuota moderadora -->
      <div>
        <v-tooltip color="success" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small fab color="success" v-bind="attrs" v-on="on" @mousedown.prevent="dialogoCuotaModeradora = true">
              <v-icon> add </v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
    </section>

    <!-- Cuotas moderadoras listadas en v-cards -->
    <v-row class="ma-0" style="height: 75vh;">
      <v-col cols="12" md="12" class="mt-4 pa-0 contenedor-card">
        <v-card outlined class="mb-1 elevation-2" v-for="item in cardCuota.items" :key="item.ano">
          <v-card-text>
            <v-row>

              <!-- Primer columna con la información general -->
              <v-col cols="12" md="3" style="color: black;" class="d-flex align-center">
                <v-row class="ps-10">
                  <v-col cols="12" md="12" class="pa-1">
                    <span class="info-card">Año:</span>{{ item.ano }}
                  </v-col>
                  <v-col cols="12" md="12" class="pa-1">
                    <span class="info-card">Incremento: </span> {{ parseFloat(item.incremento).toFixed(2) }}%
                  </v-col>
                  <v-col cols="12" md="12" class="pa-1">
                    <span class="info-card">Estado: </span> <span class="font-weight-black"
                      :style="item.eliminado === false ? 'color: green' : 'color: red'">
                      {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Segunda columna con la tabla -->
              <v-col cols="12" md="6" align="center">
                <v-simple-table dense class="elevation">
                  <thead>
                    <tr>
                      <th class="text-center" width="33.33%">NIVEL</th>
                      <th class="text-center" width="33.33%">VALOR</th>
                      <th class="text-center" width="33.33%">VALOR (CENTENA MÁS CERCANA)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="nivel in item.niveles" :key="nivel.nivelValue">
                      <td class="text-center">{{ nivel.nivelValue }}</td>
                      <td class="text-center">$ {{ formatoPrecio(nivel.valor) }}</td>
                      <td class="text-center">$ {{ formatoPrecio(nivel.valorAproximacion) }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>

              <!-- Tercera columna con las acciones -->
              <v-col cols="12" md="3" align="center" class="d-flex align-center">
                <v-row class="pe-10">
                  <v-col cols="12" md="12" class="pa-0">
                    <span class="info-card">Acciones</span>
                  </v-col>
                  <v-col cols="12" md="12" class="pa-0">
                    <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                      <template v-slot:activator="{ on }">
                        <v-btn icon large v-on="on" class="ms-2 me-2" @click="item.eliminado === false && abrirDialogoEditar(item)">
                          <v-icon size="30px" :color="item.eliminado === false ? 'orange' : 'gray'">border_color</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ item.eliminado === false ? 'Editar' : 'Cuota moderadora inactiva' }}</span>
                    </v-tooltip>

                    <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'gray'">
                      <template v-slot:activator="{ on }">
                        <v-btn icon large class="ms-2 me-2" v-on="on" @click="item.eliminado === false && abrirDialogoEstado(item)">
                          <v-icon size="30px" :color="item.eliminado === false ? 'error' : 'gray'">person_add_disabled</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ item.eliminado === false ? 'Inactivar' : 'Cuota moderadora inactiva' }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Paginado -->
    <v-row class="paginado">
      <span class="me-8">Items por página:</span>
      <div class="select">
        <v-select class="opciones" hide-details v-model="cardCuota.itemsPerPage" :menuProps="{ maxWidth: 49.97 }"
          :items="cardCuota.opciones">
        </v-select>
      </div>

      <span style="margin: 0 28px;"> {{ rangoItems }} de {{ cardCuota.totalElements }}</span>
      <v-btn icon :disabled="cardCuota.page === 1" @click="cardCuota.page = 1">
        <v-icon>first_page</v-icon>
      </v-btn>
      <v-btn icon :disabled="cardCuota.page === 1" @click="cardCuota.page = cardCuota.page - 1">
        <v-icon>chevron_left</v-icon>
      </v-btn>
      {{ cardCuota.page }}
      <v-btn icon :disabled="cardCuota.page === cardCuota.totalPage" @click="cardCuota.page = cardCuota.page + 1">
        <v-icon>chevron_right</v-icon>
      </v-btn>
      <v-btn icon :disabled="cardCuota.page === cardCuota.totalPage" @click="cardCuota.page = cardCuota.totalPage">
        <v-icon>last_page</v-icon>
      </v-btn>
    </v-row>

    <!-- Formulario para editar o crear la cuota moderadora -->
    <v-dialog v-model="dialogoCuotaModeradora" transition="dialog-bottom-transition" max-width="33rem" persistent :editar="false">
      <!-- Mensaje -->
      <v-card v-if="!todosInactivos && !editar">
        <v-card-title class="d-flex justify-end pa-1 pb-0">
          <v-btn small icon @click="dialogoCuotaModeradora = false"><v-icon color="primary">close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div class=" d-flex mb-2 mensaje">
            <v-icon class="ma-2" color="primary">info</v-icon>
            <span class="d-flex align-center pa-1">Para crear una cuota moderadora debe inactivar la del año anterior.</span>
          </div>
        </v-card-text>
      </v-card>

      <!-- Formulario -->
      <v-card v-else>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ tituloDialogo }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form ref="cuotaModeradoraForm">
            <div class="d-flex">
              <!-- Campo para almacenar el año de la cuota moderadora -->
              <v-text-field class="me-2" v-model="cuotaModeradora.ano" label="Año" dense outlined :disabled="editar"
                :error-messages="validateYear" :rules="rules.required" @keypress="soloNumeros" @paste.prevent
                @input="validarAno()">
              </v-text-field>

              <!-- Campo para ingresar el porcentaje de incremento -->
              <v-text-field v-model="cuotaModeradora.incremento" label="Incremento (%)" dense outlined
                :error-messages="validatePercentage" :rules="rules.required" @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>
            </div>

            <div class="d-flex filas" v-for="(nivel, index) in cuotaModeradora.niveles" :key="index">
              <span class="me-2 nivel">Nivel {{ nivel.nivel }}</span>
              <!-- Campo para ingresar el valor de la cuota moderadora -->
              <v-text-field class="me-2" v-model="nivel.valor" label="Valor" dense outlined
                :rules="rules.required" @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>

              <!-- Campo para ingresar el valor redondeado de la cuota moderadora -->
              <v-text-field v-model="nivel.valorAproximacion" label="Valor aproximado" dense outlined
                :rules="rules.required" @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>
            </div>

            <div class=" d-flex mb-2 mensaje">
              <v-icon class="ma-2" color="primary">info</v-icon>
              <span class="d-flex align-center pa-1">Verifique los valores con la norma gubernamental antes de guardar.</span>
            </div>

            <div class="d-flex justify-end">
              <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
              <v-btn color="success" :disabled="!formValid || saveDisabled" text depressed
                @click="!editar ? agregarCuota() : editarCuota()">Guardar</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Diálogo para cambiar el estado de una cuota moderadoras -->
    <v-dialog v-model="dialogoEstado" transition="dialog-bottom-transition" max-width="26rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">¿Desea inactivar la cuota moderadora?</span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoEstado = false">No</v-btn>
            <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  name: "CuotaModeradora",
  data() {
    return {
      buscar: {
        ano: '',
        nivel: ''
      },
      niveles: ['1', '2', '3'],
      dialogoCuotaModeradora: false,
      cardCuota: {
        items: [],
        opciones: [5, 10, 15, 20],
        itemsPerPage: 5,
        page: 1,
        totalPage: 0,
      },
      cuotaModeradora: {
        ano: new Date().getFullYear(),
        incremento: '',
        niveles: [
          { nivel: 1, valor: '', valorAproximacion: '' },
          { nivel: 2, valor: '', valorAproximacion: '' },
          { nivel: 3, valor: '', valorAproximacion: '' },
        ]
      },
      saveDisabled: false,
      validateYear: '',
      validatePercentage: '',
      editar: false,
      cuotaSeleccionada: {},
      traerDatos: false,
      dialogoEstado: false,
      eliminado: false,
      todosInactivos: false,
      rules: {
        required: [
          v => !!v || "Obligatorio.",
        ],
      },
    }
  },
  mounted() {
    this.lastmodifiedby = this.auth.username.toUpperCase();
    this.listarCuotas();
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    tituloDialogo() {
      return this.editar ? 'Editar cuota moderadora' : 'Cuota moderadora';
    },
    formValid() {
      if (!this.cuotaModeradora.ano || !this.cuotaModeradora.incremento) {
        return false;
      }
      for (const nivel of this.cuotaModeradora.niveles) {
        if (!nivel.valor || !nivel.valorAproximacion) {
          return false;
        }
      }
      return true;
    },
    rangoItems() {
      const start = (this.cardCuota.page - 1) * this.cardCuota.itemsPerPage + 1;
      let end = this.cardCuota.page * this.cardCuota.itemsPerPage;
      if (end > this.cardCuota.totalElements) {
        end = this.cardCuota.totalElements;
      }
      return `${start} - ${end}`;
    }
  },
  watch: {
    'buscar.nivel': function () {
      this.listarCuotas();
      this.cardCuota.page = 1
    },
    'buscar.ano': function () {
      this.listarCuotas();
      this.cardCuota.page = 1
    },
    'cardCuota.itemsPerPage': function () {
      this.listarCuotas();
      this.cardCuota.page = 1;
    },
    'cardCuota.page': function () {
      this.listarCuotas();
    },
    'dialogoCuotaModeradora': function () {
      if (this.dialogoCuotaModeradora === true && this.todosInactivos) {
        this.validarAno();
      }
    },
    'cuotaModeradora.incremento': debounce(function () {
      if (this.cuotaModeradora.incremento && !this.editar) {
        this.validarIncremento();
      } else {
        this.validatePercentage = '';
      }
    }, 800),
    'cuotaModeradora.ano': function (newValue, oldValue) {
      if (newValue != oldValue && !this.editar) {
        this.clearCampos();
      }
    },
  },
  methods: {
    soloNumeros(event) {
      const key = event.key;
      if (!key.match(/^[0-9]+$/)) {
        event.preventDefault();
      }
    },
    puntoYNumeros(event) {
      const key = event.key;
      if (!key.match(/^[0-9.]+$/)) {
        event.preventDefault();
      }
    },
    formatoPrecio(numero) {
      const formato = new Intl.NumberFormat('en-US');
      return formato.format(numero);
    },
    listarCuotas() {
      this.$http.get(`msa-administration/api/cuotaModeradora/listar`, {
        params: {
          page: this.cardCuota.page - 1,
          size: this.cardCuota.itemsPerPage,
          nivel: this.buscar.nivel ? parseInt(this.buscar.nivel) : null,
          ano: this.buscar.ano ? this.buscar.ano : null
        }
      }).then((response) => {
        this.cardCuota.items = response.data.content;
        this.cardCuota.totalPage = response.data.totalPages;
        this.cardCuota.totalElements = response.data.totalElements;
        this.cuotasActivas();
      }).catch((error) => {
        console.log(error);
      });
    },
    async cuotasActivas() {
      const response = await this.$http.get(`msa-administration/api/cuotaModeradora/cuotasActivas`);
      this.todosInactivos = response.data.length === 0;
    },
    validarAno() {
      this.validateYear = '';
      let year = this.cuotaModeradora.ano;
      if (year) {
        if (year > new Date().getFullYear()) {
          this.validateYear = 'El año no debe ser mayor al actual.';
          this.saveDisabled = true;

        } else if (year.length < 4) {
          this.validateYear = 'Ingrese un año válido.';
          this.saveDisabled = true;
        } else {
          this.validarExistencia(year);
        }
      } else {
        this.saveDisabled = true;
      }
    },
    validarIncremento() {
      this.clearCampos();
      let percentage = this.cuotaModeradora.incremento;
      this.validatePercentage = '';
      if (percentage) {
        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(percentage)) {
          this.validatePercentage = 'Ingrese un porcentaje válido.';
        } else {
          this.validatePercentage = '';
          if (!this.editar) { this.calcularCuota() }
        }
      } else {
        this.saveDisabled = true;
      }
    },
    validarExistencia(year) {
      this.$http.get(`msa-administration/api/cuotaModeradora/validar`, {
        params: {
          ano: year
        }
      }).then((response) => {
        if (response.data.length > 0) {
          this.validateYear = 'La cuota moderadora ya existe.'
          this.saveDisabled = true;
        } else {
          this.validateYear = '';
          this.saveDisabled = false;
          this.calcularCuota();
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    calcularCuota() {
      if (this.validateYear === '' && this.cuotaModeradora.ano && this.cuotaModeradora.incremento && this.validatePercentage === '') {
        let year = Number(this.cuotaModeradora.ano) - 1;
        this.$http.get(`msa-administration/api/cuotaModeradora/calcular`, {
          params: {
            ano: year,
            incremento: this.cuotaModeradora.incremento
          }
        }).then((response) => {
          if (response.data && response.data.datos) {
            this.cuotaModeradora.niveles[0].valor = response.data.valorUno;
            this.cuotaModeradora.niveles[0].valorAproximacion = response.data.valorAproxUno === 0 ? null : response.data.valorAproxUno;
            this.cuotaModeradora.niveles[1].valor = response.data.valorDos;
            this.cuotaModeradora.niveles[1].valorAproximacion = response.data.valorAproxDos === 0 ? null : response.data.valorAproxDos;
            this.cuotaModeradora.niveles[2].valor = response.data.valorTres;
            this.cuotaModeradora.niveles[2].valorAproximacion = response.data.valorAproxTres === 0 ? null : response.data.valorAproxTres;
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    agregarCuota() {
      this.saveDisabled = true;
      const cuotas = [];
      
      this.cuotaModeradora.niveles.forEach(nivel => {
        cuotas.push({
          nivelValue: nivel.nivel,
          valor: nivel.valor,
          valorAproximacion: nivel.valorAproximacion,
          ano: this.cuotaModeradora.ano,
          incremento: this.cuotaModeradora.incremento
        });
      });
      this.$http.post(`msa-administration/api/cuotaModeradora/guardar`, cuotas)
        .then(() => {
          this.clear();
          this.listarCuotas();
        }).catch((error) => {
          console.log(error);
        });
    },
    buscarCuotas(item) {
      this.$http.get(`msa-administration/api/cuotaModeradora/buscar`, {
        params: {
          ano: item
        }
      }).then((response) => {
        this.cuotaSeleccionada = response.data;
        this.cuotaModeradora.niveles[0].valor = this.cuotaSeleccionada.valorUno;
        this.cuotaModeradora.niveles[0].valorAproximacion = this.cuotaSeleccionada.valorAproxUno;
        this.cuotaModeradora.niveles[1].valor = this.cuotaSeleccionada.valorDos;
        this.cuotaModeradora.niveles[1].valorAproximacion = this.cuotaSeleccionada.valorAproxDos;
        this.cuotaModeradora.niveles[2].valor = this.cuotaSeleccionada.valorTres;
        this.cuotaModeradora.niveles[2].valorAproximacion = this.cuotaSeleccionada.valorAproxTres;
      }).catch((error) => {
        console.log(error);
      });
    },
    abrirDialogoEditar(item) {
      this.buscarCuotas(item.ano);
      this.cuotaModeradora.ano = item.ano;
      this.cuotaModeradora.incremento = item.incremento;
      this.editar = true;
      this.dialogoCuotaModeradora = true;
    },
    editarCuota() {
      this.saveDisabled = true;
      const cuotas = [
        {
          idCuota: this.cuotaSeleccionada.idUno,
          nivelValue: 1,
          valor: this.cuotaModeradora.niveles[0].valor,
          valorAproximacion: this.cuotaModeradora.niveles[0].valorAproximacion,
          ano: this.cuotaModeradora.ano,
          incremento: this.cuotaModeradora.incremento,
          lastmodifiedby: this.lastmodifiedby
        },
        {
          idCuota: this.cuotaSeleccionada.idDos,
          nivelValue: 2,
          valor: this.cuotaModeradora.niveles[1].valor,
          valorAproximacion: this.cuotaModeradora.niveles[1].valorAproximacion,
          ano: this.cuotaModeradora.ano,
          incremento: this.cuotaModeradora.incremento,
          lastmodifiedby: this.lastmodifiedby
        },
        {
          idCuota: this.cuotaSeleccionada.idTres,
          nivelValue: 3,
          valor: this.cuotaModeradora.niveles[2].valor,
          valorAproximacion: this.cuotaModeradora.niveles[2].valorAproximacion,
          ano: this.cuotaModeradora.ano,
          incremento: this.cuotaModeradora.incremento,
          lastmodifiedby: this.lastmodifiedby
        },
      ];
      this.$http.put(`msa-administration/api/cuotaModeradora/actualizar`, cuotas)
        .then(() => {
          this.dialogoCuotaModeradora = false;
          this.listarCuotas();
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    abrirDialogoEstado(item) {
      this.eliminado = item.eliminado;
      this.buscarCuotas(item.ano);
      this.dialogoEstado = true;
    },
    cambiarEstado() {
      this.eliminado = (this.eliminado == false) ? true : false;
      const estados = [
        {
          idCuota: this.cuotaSeleccionada.idUno,
          eliminado: this.eliminado,
          lastmodifiedby: this.lastmodifiedby
        },
        {
          idCuota: this.cuotaSeleccionada.idDos,
          eliminado: this.eliminado,
          lastmodifiedby: this.lastmodifiedby
        },
        {
          idCuota: this.cuotaSeleccionada.idTres,
          eliminado: this.eliminado,
          lastmodifiedby: this.lastmodifiedby
        },
      ]
      this.$http.put(`msa-administration/api/cuotaModeradora/inactivar`, estados)
        .then(() => {
          this.listarCuotas();
          this.clearCampos();
          this.dialogoEstado = false;
        }).catch((error) => {
          console.log(error);
        });
    },
    clear() {
      this.dialogoCuotaModeradora = false;
      this.dialogoEstado = false;
      setTimeout(() => {
        this.validateYear = '';
        this.editar = false;
        this.validatePercentage = '';
        this.saveDisabled = false;
        this.cuotaModeradora.incremento = '';
        this.cuotaModeradora.ano = new Date().getFullYear();
        this.traerDatos = false;
        this.clearCampos();
        if (this.$refs.cuotaModeradoraForm) {
          this.$refs.cuotaModeradoraForm.resetValidation();
        }
      }, 500);
    },
    clearCampos() {
      this.cuotaSeleccionada = {};
      this.cuotaModeradora.niveles[0].valor = '';
      this.cuotaModeradora.niveles[0].valorAproximacion = '';
      this.cuotaModeradora.niveles[1].valor = '';
      this.cuotaModeradora.niveles[1].valorAproximacion = '';
      this.cuotaModeradora.niveles[2].valor = '';
      this.cuotaModeradora.niveles[2].valorAproximacion = '';
    }
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                      ####### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.filtro {
  width: 25%;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

.filas {
  width: 100% !important;
}

.nivel {
  display: flex;
  text-align: center;
  height: 40px;
}

.mensaje {
  border-radius: 4px;
  background-color: #b4d1ff85;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
  font-weight: bold;
  color: black !important;
  height: 28px;
}

::v-deep .elevation div table tbody tr td {
  height: 25px;
}

.elevation {
  border: solid rgb(181, 178, 178) 0.5px;
  color: black;
  width: 90%;
}

.info-card {
  color: black;
  padding: 3px;
  font-weight: bold;
}

.paginado {
  font-size: small;
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0;
}

.paginado button {
  border: none;
  margin: 0 7px;
}

.opciones {
  margin: 0;
  padding: 12px 0;
}

::v-deep .opciones .v-input__slot {
  width: 47.49px !important;
  font-size: small;
}

.contenedor-card {
  max-height: 73vh;
  overflow-y: auto;
}

.select {
  width: 4%;
}
</style>